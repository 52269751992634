import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { AbstractControl, ValidatorFn, Validators, ValidationErrors, FormGroup, FormBuilder } from "@angular/forms";
import { EMPTY } from "rxjs";
import { AppConstants } from "src/app/shared/constants/app-constants";
import { Manufacturer, RoofingWRXBranch } from "src/app/core/data/models/AppInitializationData";
import { ManufacturerInvitation, PayeeOption } from "src/app/core/data/models/ManufacturerInvitation";
import stateList from "../../../core/data/states";
import { AppState } from "src/app/shared/services/app-state";
import { ProfileService } from "src/app/shared/services/profile.service";
import { HttpErrorResponse } from "@angular/common/http";
import { catchError, map, tap } from "rxjs/operators";
import { MessageService } from "primeng/api";
import { Router } from "@angular/router";

interface CompanyInvitation extends ManufacturerInvitation {
  inviterCompanyId: number;
}

@Component({
  selector: "app-admin-invitation",
  templateUrl: "./admin-invitation.component.html",
  styleUrls: ["./admin-invitation.component.scss"],
})
export class AdminInvitationComponent implements OnInit {
  @Output() onCancel = new EventEmitter();

  errorMessage: string;
  states = stateList;
  payeeOptions = [PayeeOption.Manufacturer, PayeeOption.Company];
  branches: RoofingWRXBranch[] = [];
  isRPAdmin = this.appState.isAdminOrRPAdmin;
  inviterManufacturers: Manufacturer[] = this.appState.isRPAdmin
    ? this.appState.managedManufacturers
    : this.appState.profileManufacturer;
  addCompany: boolean = false;
  message: boolean = false;
  manufacturers: Manufacturer[] = this.appState.managedManufacturers;
  roofingWRXBranches: RoofingWRXBranch[] = [];
  profile = this.appState.currentProfile;
  roofingWRXEnabled = this.appState.roofingWRXEnabled;
  isLoading: Boolean = true;
  manufacturer: Manufacturer = this.appState.currentProfile.manufacturer;

  inviteForm: FormGroup = this.fb.group({
    manufacturerId: [this.manufacturer?.id ?? "", Validators.required],
    role: ["normal"],
    firstName: ["", Validators.required],
    lastName: ["", Validators.required],
    email: ["", Validators.required],
    companyName: [""],
    companyPostalCode: [""],
    companyState: ["AL"],
    companyStreet: [""],
    companyCity: [""],
    companyPhoneNumber: [""],
    inviterCompanyId: [this.manufacturer?.id ?? "", Validators.required],
    companyId: [this.manufacturer?.id ?? "", Validators.required],
    contactPhoneNumber: ["", Validators.required],
    payee: [PayeeOption.Manufacturer],
    roofingWRXBranch: ["", [this.roofingWRXBranchValidator()]],
    enableRoofingWRXCheckBox: [false],
  });

  constructor(
    private fb: FormBuilder,
    public appState: AppState,
    private profileService: ProfileService,
    private messageService: MessageService,
    private router: Router
  ) {}

  get companyId() {
    return this.inviteForm.get("companyId");
  }

  get inviterCompanyId() {
    return this.inviteForm.get("inviterCompanyId");
  }

  get firstName() {
    return this.inviteForm.get("firstName");
  }

  get companyName() {
    return this.inviteForm.get("companyName");
  }

  get companyState() {
    return this.inviteForm.get("companyState");
  }

  get companyPostalCode() {
    return this.inviteForm.get("companyPostalCode");
  }

  get companyCity() {
    return this.inviteForm.get("companyCity");
  }

  get companyStreet() {
    return this.inviteForm.get("companyStreet");
  }

  get companyPhoneNumber() {
    return this.inviteForm.get("companyPhoneNumber");
  }

  get lastName() {
    return this.inviteForm.get("lastName");
  }

  get email() {
    return this.inviteForm.get("email");
  }

  get contactPhoneNumber() {
    return this.inviteForm.get("contactPhoneNumber");
  }

  get payee() {
    return this.inviteForm.get("payee");
  }

  get roofingWRXBranch() {
    return this.inviteForm.get("roofingWRXBranch");
  }

  get role() {
    return this.inviteForm.get("role");
  }

  get enableRoofingWRXCheckBox() {
    return this.inviteForm.get("enableRoofingWRXCheckBox");
  }

  ngOnInit(): void {
    this.appState.profile$.subscribe((profile) => {
      this.inviterManufacturers = this.appState.isRPAdmin
        ? this.appState.managedManufacturers
        : this.appState.profileManufacturer;
      this.inviterCompanyId.setValue(this.appState.currentProfile.manufacturer.id);
    });
  }

  ngAfterViewInit() {
    this.isLoading = false;
  }

  initializeForm() {
    // pull list of local RoofingWRX branches
    if (this.roofingWRXEnabled) {
      this.payee.valueChanges.subscribe(() => {});
    }
  }

  onPayeeChange(option: string): void {
    this.payee.setValue(option);
    if (this.roofingWRXEnabled) {
      this.roofingWRXBranch.updateValueAndValidity();
    }
  }

  roofingWRXBranchValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (
        this.roofingWRXEnabled &&
        this.roofingWRXBranches.length > 1 &&
        this.payee.value == PayeeOption.Manufacturer &&
        control.value.length == 0
      ) {
        return { required: { value: control.value } };
      }
      return null;
    };
  }

  onClickAddCompany(value: boolean) {
    this.addCompany = value;

    if (value) {
      this.inviteForm.controls["companyId"].clearValidators();
      this.inviteForm.controls["companyName"].setValidators([Validators.required]);
      this.inviteForm.controls["companyStreet"].setValidators([Validators.required]);
      this.inviteForm.controls["companyCity"].setValidators([Validators.required]);
      this.inviteForm.controls["companyState"].setValidators([Validators.required]);
      this.inviteForm.controls["companyPostalCode"].setValidators([Validators.required]);
      this.inviteForm.controls["companyPhoneNumber"].setValidators([Validators.required]);
    } else {
      this.inviteForm.controls["companyId"].setValidators([Validators.required]);
      this.inviteForm.controls["companyName"].clearValidators();
      this.inviteForm.controls["companyStreet"].clearValidators();
      this.inviteForm.controls["companyCity"].clearValidators();
      this.inviteForm.controls["companyState"].clearValidators();
      this.inviteForm.controls["companyPostalCode"].clearValidators();
      this.inviteForm.controls["companyPhoneNumber"].clearValidators();
    }
    this.inviteForm.controls["companyId"].updateValueAndValidity();
    this.inviteForm.controls["companyName"].updateValueAndValidity();
    this.inviteForm.controls["companyStreet"].updateValueAndValidity();
    this.inviteForm.controls["companyCity"].updateValueAndValidity();
    this.inviteForm.controls["companyState"].updateValueAndValidity();
    this.inviteForm.controls["companyPostalCode"].updateValueAndValidity();
    this.inviteForm.controls["companyPhoneNumber"].updateValueAndValidity();
  }

  onClickRequest() {
    this.message = true;
  }

  sendInvite() {
    this.errorMessage = null;

    const invite: CompanyInvitation = {
      enableRoofingWRX: this.enableRoofingWRXCheckBox.value,
      role: this.role?.value,
      email: this.email?.value,
      firstName: this.firstName?.value,
      lastName: this.lastName?.value,
      companyName: this.companyName?.value,
      companyAddress: {
        postalCode: this.companyPostalCode?.value,
        street: this.companyStreet?.value,
        city: this.companyCity?.value,
        state: this.companyState?.value,
      },
      companyPhoneNumber: this.companyPhoneNumber?.value,
      inviterCompanyId: this.inviterCompanyId?.value,
      companyId: this.companyId?.value,
      contactPhoneNumber: this.contactPhoneNumber?.value,
      payee: this.payee?.value,
      roofingWRXBranch: this.roofingWRXBranch?.value,
    };

    this.profileService
      .createInvitation(invite)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: error.error ?? AppConstants.RoofingPassportCommunicationError,
          });
          return EMPTY;
        }),
        tap(() => {
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Invitation successfully sent.",
          });
          this.returnToProjectList();
        })
      )
      .subscribe();
  }

  returnToProjectList() {
    this.router.navigate(["../jobs"]);
  }
}
