<p-panel>
  <ng-template pTemplate="header">
    <div class="flex align-items-center gap-2">
      <h5 class="mt-3">Invite to Company</h5>
      <br />
    </div>
  </ng-template>
  <ng-container *ngIf="isLoading">
    <p-skeleton width="60rem" height="6rem"></p-skeleton>
  </ng-container>
  <div [formGroup]="inviteForm" *ngIf="!isLoading">
    <div class="grid">
      <div class="col-4" *ngIf="isRPAdmin">
        <div class="text-left p-4">
          <div class="form-label">Inviting Company</div>
          <p-dropdown
            style="width: 100%"
            id="inviterCompanyId"
            [options]="inviterManufacturers"
            optionLabel="name"
            optionValue="id"
            formControlName="inviterCompanyId"
          ></p-dropdown>
        </div>
      </div>
    </div>
    <p-divider></p-divider>
    <div class="grid">
      <div class="col-4">
        <div class="text-left p-4">
          <div class="form-label">Email<sup>*</sup></div>
          <input
            type="text"
            class="p-inputtext-sm"
            pInputText
            formControlName="email"
            maxlength="100"
            style="width: 100%"
          />
          <div class="form-error">
            <span *ngIf="email.invalid && (email.touched || email.dirty)">An email is required</span>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="text-left p-4">
          <div class="form-label">First Name<sup>*</sup></div>
          <input type="text" class="p-inputtext-sm" pInputText formControlName="firstName" maxlength="100" />
          <div class="form-error">
            <span *ngIf="firstName.invalid && (firstName.touched || firstName.dirty)">First Name is required</span>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="text-left p-4">
          <div class="form-label">Last Name<sup>*</sup></div>
          <input type="text" class="p-inputtext-sm" pInputText formControlName="lastName" maxlength="100" />
          <div class="form-error">
            <span *ngIf="lastName.invalid && (lastName.touched || lastName.dirty)">Last Name is required</span>
          </div>
        </div>
      </div>
    </div>
    <p-divider></p-divider>
    <div class="grid">
      <div class="col-4">
        <div class="flex flex-column h-full justify-content-evenly pl-4 radioButtonsContainer">
          <p-radioButton
            name="role"
            value="normal"
            formControlName="role"
            label="Normal"
            (onClick)="role.setValue('normal')"
          ></p-radioButton>
          <!--          <p-radioButton-->
          <!--            name="role"-->
          <!--            value="admin"-->
          <!--            formControlName="role"-->
          <!--            label="Admin"-->
          <!--            (onClick)="role.setValue('admin')"-->
          <!--          ></p-radioButton>-->
        </div>
      </div>
      <div class="col-4">
        <div class="text-left p-4">
          <div class="form-label">Contact Phone Number<sup>*</sup></div>
          <input
            pInputText
            class="p-inputtext-sm"
            type="text"
            formControlName="contactPhoneNumber"
            placeholder=""
            maxlength="100"
          />
          <div class="form-error">
            <span *ngIf="contactPhoneNumber.invalid && (contactPhoneNumber.touched || contactPhoneNumber.dirty)"
              >A contact phone number is required</span
            >
          </div>
        </div>
      </div>
      <div class="col-4 flex align-items-center">
        <div class="text-left p-4">
          <p-checkbox
            formControlName="enableRoofingWRXCheckBox"
            [binary]="true"
            inputId="enableRoofingWRXCheckBox"
          ></p-checkbox>
          <label class="mb-0 ml-2" for="enableRoofingWRXCheckBox">Enable Roofing WRX </label>
        </div>
      </div>
    </div>
    <p-divider></p-divider>
    <div class="grid" *ngIf="!addCompany">
      <div class="col-4">
        <div class="text-left p-4">
          <div class="form-label">Company<sup>*</sup></div>
          <p-dropdown
            id="companyId"
            [options]="manufacturers"
            optionLabel="name"
            optionValue="id"
            formControlName="companyId"
            [filter]="true"
          ></p-dropdown>
          <div class="form-error">
            <span *ngIf="companyId.invalid && (companyId.touched || companyId.dirty)">A Company is required</span>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="text-left p-4">
          <div class="form-label"><sup>Or</sup></div>
          <p-button label="Add Company" (onClick)="onClickAddCompany(true)"></p-button>
        </div>
      </div>
    </div>
    <div class="grid" *ngIf="addCompany">
      <div class="col-4">
        <div class="text-left p-4">
          <div class="form-label">Company Name<sup>*</sup></div>
          <input type="text" class="p-inputtext-sm" pInputText formControlName="companyName" maxlength="100" />
          <div class="form-error">
            <span *ngIf="companyName.invalid && (companyName.touched || companyName.dirty)"
              >Company Name is required</span
            >
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="text-left p-4">
          <div class="form-label">Company Postal Code<sup>*</sup></div>
          <input type="text" class="p-inputtext-sm" pInputText formControlName="companyPostalCode" maxlength="100" />
          <div class="form-error">
            <span *ngIf="companyPostalCode.invalid && (companyPostalCode.touched || companyPostalCode.dirty)"
              >Company Postal Code is required</span
            >
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="text-left p-4">
          <div class="form-label">Company Phone Number<sup>*</sup></div>
          <input type="text" class="p-inputtext-sm" pInputText formControlName="companyPhoneNumber" maxlength="100" />
          <div class="form-error">
            <span *ngIf="companyPhoneNumber.invalid && (companyPhoneNumber.touched || companyPhoneNumber.dirty)"
              >Company Phone Number is required</span
            >
          </div>
        </div>
      </div>
    </div>
    <p-divider></p-divider>
    <div class="grid" *ngIf="addCompany">
      <div class="col-4">
        <div class="text-left p-4">
          <div class="form-label">Company Street<sup>*</sup></div>
          <input type="text" class="p-inputtext-sm" pInputText formControlName="companyStreet" maxlength="100" />
          <div class="form-error">
            <span *ngIf="companyStreet.invalid && (companyStreet.touched || companyStreet.dirty)"
              >Company Street is required</span
            >
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="text-left p-4">
          <div class="form-label">Company City<sup>*</sup></div>
          <input type="text" class="p-inputtext-sm" pInputText formControlName="companyCity" maxlength="100" />
          <div class="form-error">
            <span *ngIf="companyCity.invalid && (companyCity.touched || companyCity.dirty)"
              >Company City is required</span
            >
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="text-left p-4">
          <div class="form-label">Company State<sup>*</sup></div>
          <p-dropdown
            id="companyState"
            [options]="states"
            optionLabel="name"
            optionValue="abbreviation"
            formControlName="companyState"
          ></p-dropdown>
          <div class="form-error">
            <span *ngIf="companyState.invalid && (companyState.touched || companyState.dirty)"
              >Company State is required</span
            >
          </div>
        </div>
      </div>
    </div>
    <p-divider></p-divider>
    <div class="col-4">
      <div class="text-left p-4">
        <div fxFlex="1 1 100%" class="form-field">
          <div class="form-label mb-3">Billing<sup>*</sup></div>
          <div *ngFor="let option of payeeOptions" class="field-radiobutton">
            <p-radioButton
              [value]="option"
              formControlName="payee"
              label="{{ option === 'Manufacturer' ? 'Inviting Company' : 'Invited Company' }}"
              (onClick)="onPayeeChange(option)"
            ></p-radioButton>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-4"
      *ngIf="this.roofingWRXEnabled && payee.value == payeeOptions[0] && this.roofingWRXBranches.length > 1"
    >
      <div class="text-left p-4">
        <div class="form-label">Roofing WRX Branch<sup>*</sup></div>
        <select [formControl]="roofingWRXBranch">
          <option value="">-- Select Branch --</option>
          <option *ngFor="let branch of roofingWRXBranches; let i = index" value="{{ branch.name }}">
            {{ branch.name }}
          </option>
        </select>
        <div class="form-error">
          <span *ngIf="roofingWRXBranch.invalid && (roofingWRXBranch.touched || roofingWRXBranch.dirty)"
            >A branch selection is required</span
          >
        </div>
      </div>
    </div>
    <p-divider></p-divider>
    <br />
    <div class="grid">
      <div class="col-8">
        <p-message
          *ngIf="!message"
          severity="info"
          text="Company information will be maintained by Roofing Passport admins until further notice. Please email support-roofingpassport@sherwin.com if you need assistance."
          class="text-xs"
        ></p-message>
        <p-message
          *ngIf="message"
          severity="info"
          text="Company information and new company affiliations will be maintained by Roofing Passport admins until further notice. Your request is being processed; the invitation will be completed by Roofing Passport Administrators."
          class="text-xs"
        ></p-message>
      </div>
      <div class="col-4 flex justify-content-end align-items-center">
        <div class="text-right px-4">
          <button
            pButton
            pRipple
            label="Cancel"
            icon="pi pi-times"
            class="p-button-text p-button-secondary"
            (click)="returnToProjectList()"
          ></button>
          <p-button
            label="Send Invite"
            class="p-button-secondary ml-2"
            type="submit"
            [disabled]="!inviteForm.valid"
            (onClick)="sendInvite()"
          ></p-button>
        </div>
      </div>
    </div>
  </div>
</p-panel>
